export const hero = {
  title:
    'Create a User Person in few seconds, <br/> Frankestein will be proud of you',
  firstTooltip:
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut culpa recusandae officiis repudiandae quaerat omnis earum tenetur odit enim minima.',
  secondTooltip:
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut culpa recusandae officiis repudiandae quaerat omnis earum tenetur odit enim minima.',
  thirdTooltip:
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut culpa recusandae officiis repudiandae quaerat omnis earum tenetur odit enim minima.',
  fourthTooltip:
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut culpa recusandae officiis repudiandae quaerat omnis earum tenetur odit enim minima.',
};
