import { FC } from 'react';
import { Intro } from '../components';

export const About: FC = () => {
  return (
    <>
      <Intro />
    </>
  );
};
