export const toolBar = {
  attributes: {
    default: {
      label: 'Label',
      placeholder: 'Placeholder',
    },
    title: {
      label: 'Titolo',
      placeholder: 'Inserisci qui il titolo',
    },
    subtitle: {
      label: 'Sottotitolo',
      placeholder: 'Inserisci qui il sottotitolo',
    },
    body: {
      label: 'Corpo',
      placeholder: 'Inserisci qui il corpo',
    },
    items: {
      label: 'Nome',
      placeholder: 'Nome del nuovo elemento...',
    },
    image: {
      label: 'Immagine',
      addOnePlaceholder: 'Inserisci un nuovo elemento',
      placeholder: 'Carica la tua immagine',
    },
  },
  style: {
    labels: {
      color: 'Colore testo',
      fontSize: 'Dimensione font',
      backgroundColor: 'Colore sfondo',
      borderColor: 'Colore bordi',
      borderWidth: 'Spessore bordo',
      borderRadius: 'Arrotondamento bordo',
      progressBarTrackBackgroundColor: 'Colore barra dei progressi',
      progressBarFilledTrackBackgroundColor:
        'Colore di sfondo barra dei progressi',
      progressBarBorderColor: 'Colore bordo barra dei progressi',
      progressBarBorderWidth: 'Spessore bordo barra dei progressi',
      progressBarBorderRadius: 'Smussatura barra dei progressi',
      checkboxBorderColor: 'Colore bordo casella',
      checkboxCheckColor: 'Colore spunta',
      colorRateIcon: 'Colore icone valutazione',
      objectFit: 'Adatta immagine',
      icon: 'Tipologia icona',
      rowGap: 'Distanza righe',
      columnGap: 'Distanza colonne',
      pageBackground: 'Sfondo pagina',
      containerPaddingX: 'Margine orizzontale',
      containerPaddingY: 'Margine verticale',
    },
    buttons: {
      contain: 'Contain',
      cover: 'Cover',
    },
  },
  errors: {
    textualContentTooLong: 'Il testo inserito è troppo lungo',
    itemNameTooLong: 'Il nome inserito è troppo lungo!',
    noText: 'Nessun testo inserito!',
    impossibleToDeleteItem: 'Deve esistere almeno un elemento',
  },
  draggableBlocksLabels: {
    text: 'Testo',
    image: 'Immagine',
    'image-list': 'Galleria',
    'text-list': 'Elenco',
    checkbox: 'Checkbox',
    progress: 'Progressivo',
    rate: 'Valutazione',
  },
  general: {
    back: 'Indietro',
    blocks: 'Blocchi',
    globalStyle: 'Stili globali',
    attributes: 'Attributi',
    style: 'Stile',
  },
  attributeAccordionLabel: {
    title: 'Stile titolo',
    subtitle: 'Stile sottotitolo',
    body: 'Stile corpo',
    image: 'Stile immagine',
    'image-list': 'Stile galleria',
    progress: 'Stile progressivo',
    checkbox: 'Stile checkbox',
    'text-list': 'Stile elenco',
    rate: 'Stile valutazione',
  },
};
