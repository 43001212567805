export const about = {
  intro: {
    title: 'E se potessimo velocizzare il tuo lavoro?',
    body: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi minus distinctio magnam eligendi aut error ab commodi illum optio, sit atque laborum tenetur beatae assumenda, nobis sapiente, praesentium quae! Sint?',
  },
  madeBy: {
    title: 'Prodotto da VLK studio',
    body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam aliquid consequatur dolorum quas, corrupti minus sed recusandae! Autem, provident sunt ipsa perferendis doloribus maxime aliquam non quos hic iste exercitationem. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam aliquid consequatur dolorum quas, corrupti minus sed recusandae! Autem, provident sunt ipsa perferendis doloribus maxime aliquam non quos hic iste exercitationem.',
  },
  goToFaq: {
    title: 'Ancora in dubbio ?',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis maecenas id tincidunt quis aliquam scelerisque',
    buttonLabel: 'Vedi le FAQ',
  },
};
