import { hero } from './hero';
import { form } from './form';
import { profile } from './profile';
import { toast } from './toast';

export const auth = {
  hero,
  form,
  profile,
  toast,
};
