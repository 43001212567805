export const toolBar = {
  attributes: {
    default: {
      label: 'Label',
      placeholder: 'Placeholder',
    },
    title: {
      label: 'Title',
      placeholder: 'Insert title here',
    },
    subtitle: {
      label: 'Subtitle',
      placeholder: 'Insert subtitle here',
    },
    body: {
      label: 'Body',
      placeholder: 'Insert body here',
    },
    items: {
      label: 'Name',
      placeholder: 'Insert name for new item',
    },
    image: {
      label: 'Image',
      addOnePlaceholder: 'Add a new item',
      placeholder: 'Upload your image',
    },
  },
  style: {
    labels: {
      color: 'Colore testo',
      fontSize: 'Dimensione font',
      backgroundColor: 'Background-color',
      borderColor: 'Border-color',
      borderWidth: 'Border-width',
      borderRadius: 'Border-radius',
      progressBarTrackBackgroundColor: 'Background progress bar ',
      progressBarFilledTrackBackgroundColor: 'Color progress bar',
      progressBarBorderColor: 'Border-color progress bar',
      progressBarBorderWidth: 'Border-width progress bar',
      progressBarBorderRadius: 'Border-radius progress bar',
      checkboxBorderColor: 'Border-color box',
      checkboxCheckColor: 'Check color',
      colorRateIcon: 'Rate icon color',
      objectFit: 'Image fit',
      icon: 'Type Icon',
      rowGap: 'Row gap',
      columnGap: 'Column gap',
      pageBackground: 'Page background',
      containerPaddingX: 'Horizontal margin',
      containerPaddingY: 'Vertical margin',
    },
    buttons: {
      contain: 'Contain',
      cover: 'Cover',
    },
  },
  errors: {
    textualContentTooLong: 'The entered text is too long',
    itemNameTooLong: 'The name entered is too long',
    noText: 'No text entered',
    impossibleToDeleteItem: 'There must be at least one element',
  },
  draggableBlocksLabels: {
    text: 'Text',
    image: 'Image',
    'image-list': 'Image-list',
    'text-list': 'Text-list',
    checkbox: 'Checkbox',
    progress: 'Progress',
    rate: 'Rate',
  },
  general: {
    back: 'Back',
    blocks: 'Blocks',
    globalStyle: 'Global styles',
    attributes: 'Attributes',
    style: 'Style',
  },
  attributeAccordionLabel: {
    title: 'Title style',
    subtitle: 'Subtitle style',
    body: 'Body style',
    image: 'Image style',
    'image-list': 'Image-list style',
    progress: 'Progress style',
    checkbox: 'Checkbox style',
    'text-list': 'Text-list style',
    rate: 'Rate style',
  },
};
