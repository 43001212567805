export const hero = {
  title:
    'Crea una User Persona in pochi secondi, <br/> Frankestein sarà orgoglioso di te',
  firstTooltip:
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut culpa recusandae officiis repudiandae quaerat omnis earum tenetur odit enim minima.',
  secondTooltip:
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut culpa recusandae officiis repudiandae quaerat omnis earum tenetur odit enim minima.',
  thirdTooltip:
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut culpa recusandae officiis repudiandae quaerat omnis earum tenetur odit enim minima.',
  fourthTooltip:
    'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut culpa recusandae officiis repudiandae quaerat omnis earum tenetur odit enim minima.',
};
