import template from './image/persona.png';
import firstInput from './image/personaInput1.png';
import secondInput from './image/personaInput2.png';
import thirdInput from './image/personaInput3.png';
import fourthInput from './image/personaInput4.png';

export const imgLibrary = {
  template,
  firstInput,
  secondInput,
  thirdInput,
  fourthInput,
};
