export const featureShowcase = {
    '1': {
        title: 'Trascina e rilascia',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nec hac diam. Cursus nec hac diam. Cursus nec hac diam.'
    },
    '2': {
        title: 'Ridimensiona i blocchi',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nec hac diam. Cursus nec hac diam. Cursus nec hac diam.'
    },
    '3': {
        title: 'Modifica il contenuto',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nec hac diam. Cursus nec hac diam. Cursus nec hac diam.'
    },
    sectionTitle: 'Gestisci la struttura delle tue '
}