export const faq = {
  question1: {
    question: 'Auth question 11',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question2: {
    question: 'Auth question 2',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question3: {
    question: 'Builder question 11',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question4: {
    question: 'Builder question 222',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question5: {
    question: 'Template question 1',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question6: {
    question: 'Template question 12',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question7: {
    question: 'Share question 1',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question8: {
    question: 'Share question c',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
};
