import { extendTheme } from '@chakra-ui/react';
import {
  colors,
  radii,
  shadows,
  borders,
  typography,
  fonts,
  textStyles,
  outline,
} from './style';
import { Heading } from './components';
import {
  Button,
  Link,
  Input,
  Tabs,
  Menu,
  Checkbox,
  Radio,
  Slider,
  Progress,
  Tag,
  Tooltip,
  Modal,
  Avatar,
  Alert,
  Switch,
  Badge,
  Spinner,
  Accordion,
  Textarea,
  NumberInput,
  Select,
} from './components';

const config = {
  colors,
  radii,
  shadows,
  borders,
  fonts,
  textStyles,
  outline,
  styles: {
    global: {
      ...typography,
    },
  },
  components: {
    Avatar,
    Accordion,
    Heading,
    Button,
    Link,
    Input,
    Tabs,
    Menu,
    Checkbox,
    Radio,
    Slider,
    Progress,
    Tag,
    Tooltip,
    Modal,
    Alert,
    Switch,
    Badge,
    Spinner,
    Textarea,
    NumberInput,
    Select,
  },
};
export const customTheme = extendTheme(config);
