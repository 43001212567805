export const faq = {
  question1: {
    question: 'Domanda auth 11',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question2: {
    question: 'Domanda auth 2',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question3: {
    question: 'Domanda sul builder 11',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question4: {
    question: 'Domanda sul builder 222',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question5: {
    question: 'Domanda sui template 1',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question6: {
    question: 'Domanda sui template 12',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question7: {
    question: 'Domanda sulla condivisione 1',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
  question8: {
    question: 'Domanda sulla condivisione c',
    answer:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem deserunt, similique nisi temporibus facilis quod quisquam modi porro placeat omnis officiis, doloribus distinctio eaque ea nostrum repellendus quis recusandae inventore!',
  },
};
