import { Main, Features } from '../components';

export const Landing = () => {
  return (
    <>
      <Main />
      <Features />
    </>
  );
};
