export const line = {
  tab: {
    color: 'black.0',
    _selected: {
      boxShadow: 'none',
      borderBottom: 'green.s',
      color: 'brand.primary',
    },
    _focus: {
      background: 'none',
    },
    _active: {
      background: 'none',
    },
  },
};
