export const unstyled = {
  color: 'green',
  _focus: {
    boxShadow: 'none',
    border: 'none',
    outline: 'none',
  },
  _active: {
    boxShadow: 'none',
    border: 'none',
    outline: 'none',
  },
};
