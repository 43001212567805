export const featureShowcase = {
    '1': {
        title: 'Drag and drop blocks',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nec hac diam. Cursus nec hac diam. Cursus nec hac diam.'
    },
    '2': {
        title: 'Resize blocks',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nec hac diam. Cursus nec hac diam. Cursus nec hac diam.'
    },
    '3': {
        title: 'Edit content',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nec hac diam. Cursus nec hac diam. Cursus nec hac diam.'
    },
    sectionTitle: 'Manage the structure of your '
}